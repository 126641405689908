export const RoleEnum = Object.freeze({
  Administrator: "Administrator",
  User: "User",
  Member: "Member",
  Statements: "Statements",
});

export const RoleIntersection = (hasRoleCallback, requiredRoles) => {
  var roleIntersection = requiredRoles.filter((r) => hasRoleCallback(r));

  return roleIntersection.length > 0;
};
