import React, { useEffect, useState, createContext } from "react";
import Axios from "axios";

import Loading from "./Loading";

import Keycloak from "keycloak-js/lib/keycloak.js";

export const AuthContext = createContext(null);

function Auth(props) {
  const [loading, setLoading] = useState(true);
  const [keycloak, setKeycloak] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = () => {
    setLoading(true);

    if (keycloak == null) {
      Axios.get(`api/keycloak/config`)
        .then((response) => {
          const keycloak = new Keycloak({ ...response.data });
          setKeycloak(keycloak);

          keycloak
            .init({ onLoad: "login-required", checkLoginIframe: false })
            .then((authenticated) => {
              setAuthenticated(authenticated);

              if (authenticated) {
                localStorage.setItem("token", keycloak.token);
              } else {
                keycloak.login();
              }
            })
            .catch(() => {
              console.error("Keycloak initialization failed");
            });
          setLoading(false);
        })
        .catch((exception) => {
          setLoading(false);
          if (exception.response?.status === 400) window.location.reload();

          if (exception.response?.status === 401) window.location.reload();
        });
    }
  };

  useEffect(() => {
    if (authenticated && keycloak) {
      const refreshTokenInterval = setInterval(() => {
        keycloak
          .updateToken(300) //if the token is valid for less than 5 minutes, it will be refreshed
          .then((refreshed) => {
            if (refreshed) {
              localStorage.setItem("token", keycloak.token);
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
            keycloak.logout();
          });
      }, 240000); //try to refresh every 4 minutes

      return () => clearInterval(refreshTokenInterval);
    }
  }, [authenticated, keycloak]);

  if (loading || keycloak == null) return <Loading />;

  const onTokenExpired = () => {
    keycloak.updateToken(300);
  };

  return (
    <AuthContext.Provider
      value={{ keycloak, authenticated, initialized: keycloak != null }}
    >
      {authenticated ? props.children : ""}
    </AuthContext.Provider>
  );
}

export default Auth;
