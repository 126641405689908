import React from "react";
import { Route, Switch } from "react-router";
import { useHistory, Redirect } from "react-router-dom";
import { RouterEnum } from "../utils/routers";

import ProtectedRoute from "./ProtectedRoute";

const Registration = React.lazy(() =>
  import("../pages/presentation/Registration")
);
const RegistrationSuccess = React.lazy(() =>
  import("../pages/presentation/RegistrationSuccess")
);
const ActivateAccount = React.lazy(() =>
  import("../pages/presentation/ActivateAccount")
);
const ActivateAccountSuccess = React.lazy(() =>
  import("../pages/presentation/ActivateAccountSuccess")
);
const ActivateAccountFail = React.lazy(() =>
  import("../pages/presentation/ActivateAccountFail")
);

const Dashboard = React.lazy(() => import("../pages/admin/Dashboard"));
const IncomingPayments = React.lazy(() =>
  import("../pages/admin/IncomingPayment/List")
);
const IncomingPaymentDetail = React.lazy(() =>
  import("../pages/admin/IncomingPayment/Detail")
);
const OutgoingPayments = React.lazy(() =>
  import("../pages/admin/OutgoingPayment/List")
);
const OutgoingPaymentDetail = React.lazy(() =>
  import("../pages/admin/OutgoingPayment/Detail")
);
const RefundPayments = React.lazy(() => import("../pages/admin/Refund/List"));
const RefundPaymentsDetail = React.lazy(() =>
  import("../pages/admin/Refund/Detail")
);
const VirtualPayments = React.lazy(() =>
  import("../pages/admin/VirtualPayment/List")
);
const Statements = React.lazy(() => import("../pages/admin/Statement/List"));
const Invoices = React.lazy(() => import("../pages/admin/Invoice/List"));
const Integration = React.lazy(() => import("../pages/admin/Integration"));
const Support = React.lazy(() => import("../pages/admin/Support"));
const LoginCallback = React.lazy(() =>
  import("../pages/admin/Authentication/LoginCallback")
);
const PaymentLinkOldGate = React.lazy(() =>
  import("../pages/admin/PaymentLinkOldGate")
);
const PaymentLinkNewGate = React.lazy(() =>
  import("../pages/admin/PaymentLinkNewGate")
);
const Settings = React.lazy(() => import("../pages/admin/Settings"));
const NotFoundAdmin = React.lazy(() => import("../pages/admin/NotFound"));

const Client = React.lazy(() => import("../pages/admin/Client"));

const PaymentIntents = React.lazy(() =>
  import("../pages/admin/PaymentIntent/List")
);
const PaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/PaymentIntent/Detail")
);

const Pos = React.lazy(() => import("../pages/admin/Pos/List"));

const PosDetail = React.lazy(() => import("../pages/admin/Pos/Detail"));

const RecurringPaymentIntents = React.lazy(() =>
  import("../pages/admin/RecurringPaymentIntent/List")
);

const RecurringPaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/RecurringPaymentIntent/Detail")
);

const OnDemandPaymentIntents = React.lazy(() =>
  import("../pages/admin/OnDemandPaymentIntent/List")
);

const OnDemandPaymentIntentDetail = React.lazy(() =>
  import("../pages/admin/OnDemandPaymentIntent/Detail")
);

function Routers(props) {
  const history = useHistory();

  const adminRouter = () => {
    let notAuthorizeRedirect = ""; //RouterEnum.Login.Path;

    return (
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={Dashboard}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Dashboard.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Dashboard.Path}
          component={Dashboard}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Dashboard.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.IncomingPayments.Path}
          component={IncomingPayments}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.IncomingPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.IncomingPaymentDetail.Path}
          component={IncomingPaymentDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.IncomingPaymentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OutgoingPayments.Path}
          component={OutgoingPayments}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.OutgoingPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OutgoingPaymentDetail.Path}
          component={OutgoingPaymentDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.OutgoingPaymentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RefundPayments.Path}
          component={RefundPayments}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.RefundPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RefundPaymentsDetail.Path}
          component={RefundPaymentsDetail}
          requiredRoles={RouterEnum.RefundPaymentsDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.VirtualPayments.Path}
          component={VirtualPayments}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.VirtualPayments.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Statements.Path}
          component={Statements}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Statements.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Invoices.Path}
          component={Invoices}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Invoices.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentLinkOldGate.Path}
          component={PaymentLinkOldGate}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.PaymentLinkOldGate.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentLinkNewGate.Path}
          component={PaymentLinkNewGate}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.PaymentLinkNewGate.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Integration.Path}
          component={Integration}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Integration.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Settings.Path}
          component={Settings}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Settings.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Support.Path}
          component={Support}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Support.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Client.Path}
          component={Client}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Client.Roles}
        />
        <Route
          exact
          path={RouterEnum.LoginCallback.Path}
          render={() => <LoginCallback />}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentIntents.Path}
          component={PaymentIntents}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.PaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PaymentIntentDetail.Path}
          component={PaymentIntentDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.PaymentIntentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.Pos.Path}
          component={Pos}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.Pos.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.PosDetail.Path}
          component={PosDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.PosDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RecurringPaymentIntents.Path}
          component={RecurringPaymentIntents}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.RecurringPaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.RecurringPaymentIntentDetail.Path}
          component={RecurringPaymentIntentDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.RecurringPaymentIntentDetail.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OnDemandPaymentIntents.Path}
          component={OnDemandPaymentIntents}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.OnDemandPaymentIntents.Roles}
        />
        <ProtectedRoute
          exact
          path={RouterEnum.OnDemandPaymentIntentDetail.Path}
          component={OnDemandPaymentIntentDetail}
          redirectTo={notAuthorizeRedirect}
          requiredRoles={RouterEnum.OnDemandPaymentIntentDetail.Roles}
        />

        <Route render={() => <NotFoundAdmin />} />
      </Switch>
    );
  };

  const presentationRouter = () => {
    return (
      <Switch>
        <Route
          exact
          path={RouterEnum.ObsoleteRegistration.Path}
          render={() => (
            <Redirect to={RouterEnum.ObsoleteRegistration.RedirectTo} />
          )}
        />
        <Route
          exact
          path={RouterEnum.Registration.Path}
          render={() => <Registration />}
        />
        <Route
          exact
          path={RouterEnum.RegistrationSuccess.Path}
          render={() => <RegistrationSuccess />}
        />
        <Route
          exact
          path={RouterEnum.ActivateAccount.Path}
          render={() => <ActivateAccount />}
        />
        <Route
          exact
          path={RouterEnum.ActivateAccountSuccess.Path}
          render={() => <ActivateAccountSuccess />}
        />
        <Route
          exact
          path={RouterEnum.ActivateAccountFail.Path}
          render={() => <ActivateAccountFail />}
        />
        <Route
          exact
          path={RouterEnum.RegistrationSuccess.Path}
          render={() => <RegistrationSuccess />}
        />
        <Route
          exact
          path={RouterEnum.LoginCallback.Path}
          render={() => <LoginCallback />}
        />
        <Route render={() => <NotFoundAdmin />} />
      </Switch>
    );
  };

  if (
    history.location.pathname.startsWith("/admin") ||
    history.location.pathname === "/"
  )
    return adminRouter();

  return presentationRouter();
}

export default Routers;
