import skSk from "antd/lib/locale/sk_SK";
import enUs from "antd/lib/locale/en_US";
import csCZ from "antd/lib/locale/cs_CZ";
import slSI from "antd/lib/locale/sl_SI";

export const getLocaleByShortcut = (shortcut) => {
  switch (shortcut) {
    case "sk":
      return skSk;
    case "cs":
      return csCZ;
    case "sl":
      return slSI;
    default:
      return enUs;
  }
};
