import { createReducer } from "redux-act";

import { exists } from "../../utils/LanguageEnum";
import * as action from "../actions/languageAction";

const initialState = {
  language: "en",
  isDefault: true
}

const setInitialState = () => {
  let localStorageLanguage = localStorage.getItem("language");

  if(exists(localStorageLanguage)) {
    initialState.language = localStorageLanguage;
    initialState.isDefault = false;
  }

  return initialState;
}

const languageReducer = createReducer(
  {
    [action.changeLanguage]: (state, payload) => {
      if(exists(payload))
        localStorage.setItem("language", payload);
      
      return {
        ...state,
        language: payload,
        isDefault: false
      };
    }
  },
  setInitialState()
);

export default languageReducer;
