export const LanguageEnum = Object.freeze({
    Sk: "sk",
    En: "en",
    Cs: "cs",
    Sl: "sl"
});

export const exists = code => {
    let key = Object.keys(LanguageEnum).find(key => LanguageEnum[key] === code);

    return key !== undefined;
}